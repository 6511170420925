import * as yup from 'yup'
import {
  BillingData,
  checkStock,
  Country,
  createPayment,
  DistributionCenter,
  FormattedAddress,
  Geocode,
  getAuth,
  log,
  Market,
  OrderData,
  PaymentTypes,
  processOrderCheckout,
  ProductCart,
  redirectToPaymentGateway,
  showToast,
  validateInputField,
  validateRut,
  Zone,
} from '@ecommerce/shared'
import { navigate } from 'gatsby'
import secrets from '../../../config/secrets'

export type UserData = {
  customer_name: string
  customer_last_name: string
  customer_rut: string
  customer_email: string
  customer_birth_day: string
  customer_birth_month: string
  customer_birth_year: string
}

export type Order = FormattedAddress & {
  billing: BillingData | null
  payment: string | undefined
  date: string | undefined
  marketing: boolean | undefined
  terms: boolean | undefined
}

export const validatePhone = (phone: string): boolean => {
  const isBO = secrets.COUNTRY === Country.BOLIVIA

  const withPlus = /^[+][0-9]{9,12}$/
  const withoutPlus = /^[0-9]{9,11}$/g
  let regex = phone.match(/[+]/g) ? withPlus : withoutPlus

  if (isBO) {
    regex = /^[0-9]{8,11}$/g
  }

  return regex.test(phone)
}

export const getBillingSchema = (isBolivia = false) => {
  let DNIRules = yup.string().required('Debes completar este campo')

  if (!isBolivia) {
    DNIRules = DNIRules.test('Rut Test', 'Por favor, ingresa un rut válido', (value) => validateRut(value || ''))
  }

  let shapeObject: yup.ObjectSchemaDefinition<BillingData> = {
    billing_first_name: yup
      .string()
      .required('Debes completar este campo')
      .test('Name Test', 'Debes ingresar un nombre válido', (val) => validateInputField(val ?? '', 'LETTERS_ONLY')),
    billing_last_name: yup
      .string()
      .required('Debes completar este campo')
      .test('LastName Test', 'Debes ingresar un apellido válido', (val) =>
        validateInputField(val ?? '', 'LETTERS_ONLY'),
      ),
    billing_address: yup.string().required('Debes completar este campo'),
    billing_user_id: DNIRules,
  }

  if (isBolivia) {
    shapeObject = {
      ...shapeObject,
      billing_city: yup.string().required('Debes seleccionar una ciudad'),
    }
  }

  return yup.object().shape(shapeObject)
}

const loggedCheckoutSchema = yup.object().shape({
  addressId: yup.string().required('Debes seleccionar una dirección'),
  payment: yup.string().required('Debes seleccionar una forma de pago'),
  date: yup.string().required('Debes seleccionar una fecha de despacho'),
  terms: yup.string().required('Debes aceptar los terminos y condiciones').oneOf(['true']),
  shipping_instructions: yup.string().max(200, (max) => `No debe exceder los ${max.max} caracteres`),
})

const orderSchema = yup.object().shape({
  shipping_address: yup.string().required('Selecciona una dirección válida'),
  shipping_name: yup.string().required('Debes completar este campo'),
  shipping_phone: yup
    .string()
    .required('Debes completar este campo')
    .test(
      'Phone Test',
      secrets.COUNTRY === Country.BOLIVIA
        ? 'Debe contener al menos 8 caracteres'
        : 'Debe contener al menos 9 caracteres',
      (value) => validatePhone(value || ''),
    ),
  shipping_instructions: yup.string().max(200, (max) => `No debe exceder los ${max.max} caracteres`),
  payment: yup.string().required('Debes seleccionar una forma de pago'),
  date: yup.string().required('Debes seleccionar una fecha de despacho'),
  terms: yup.string().required('Debes aceptar los términos y condiciones').oneOf(['true']),
})

const updateAddresses = (addresses: FormattedAddress[], newAddress: FormattedAddress, id?: string) => {
  addresses.forEach((address, i) => {
    if (address.id === id) addresses.splice(i, 1, newAddress)
    address.isFavorite = false // eslint-disable-line
  })

  if (!id) addresses.push(newAddress)

  return addresses
}

const updateFavorites = (addresses: FormattedAddress[], isFavorite: boolean, id?: string) => {
  const newArray = addresses.map((address) => {
    const newAddress = { ...address }
    if (isFavorite) newAddress.isFavorite = false
    if (address.id === id) newAddress.isFavorite = isFavorite

    return newAddress
  })

  return newArray
}

const getPaymentTypeChile = (label?: string) => {
  if (label?.toLowerCase().includes('mach')) {
    return PaymentTypes.MACH
  }
  if (label?.toLowerCase().includes('chek')) {
    return PaymentTypes.CHEK
  }
  if (label?.toLowerCase().includes('flowpay')) {
    return PaymentTypes.FLOW_PAY
  }
  return PaymentTypes.WEBPAY
}

const validateOrder = (
  displayRawTotal: number,
  currentCity: Market,
  orderData: OrderData,
  products: Record<string, ProductCart>,
  isLoading: boolean,
): boolean => {
  if (isLoading) return false
  if (Object.keys(products).length === 0) throw Error('No hay productos en tu orden.')
  if (!currentCity) throw Error('Error, ciudad no determinada.')
  if (!orderData.payment || !orderData.payment_type) throw new Error('Error, método de pago no seleccionado.')
  if (displayRawTotal >= 0) {
    return true
  }

  return false
}

const checkStockStatus = async (
  products: Record<string, ProductCart>,
  country: Country,
  currentCity: Market,
  checkStockCallback: (hasValidStock: boolean, products?: ProductCart[]) => void,
): Promise<ProductCart[] | undefined> => {
  log.trace('Checking stocks')
  const unavailable = await checkStock(products, country, currentCity)
  if (unavailable && unavailable.length !== 0) {
    const noStockProducts = unavailable.map(({ sku }) => products[sku])
    checkStockCallback(false, noStockProducts)
    return noStockProducts
  }
  return undefined
}

const processOrder = async (
  orderData: OrderData,
  currentCity: Market,
  currentZone: Zone,
  geocode: Geocode | undefined,
  orderId: string,
  useDifferentBillingData: boolean,
  guestUser: { firstName?: string; lastName?: string },
  distributionCenter: DistributionCenter | null,
  country: Country,
): Promise<void> => {
  log.trace('Process Checkout')
  await processOrderCheckout({
    orderData,
    currentMarket: currentCity,
    currentZone,
    currentCountryCode: country,
    geocode,
    orderId,
    isLogged: getAuth(),
    differentBillingAddress: useDifferentBillingData,
    shippingMethod: distributionCenter?.commerceLayer.shippingMethod?.id || '',
    paymentMethod: distributionCenter?.commerceLayer.paymentMethod?.id || '',
    country,
    guest: guestUser,
  })
}

const handleQrPayment = async (
  orderId: string,
  country: Country,
  orderData: OrderData,
  beforeRedirectCallback: () => Promise<void>,
): Promise<void> => {
  log.trace(`CREATING QR PAYMENT FOR ORDER ${orderId}`)

  return createPayment({
    orderId,
    country,
    paymentOptionId: Number(`${orderData.payment}`),
  }).then(async () => {
    await beforeRedirectCallback()
    navigate(`/payment/pending?toPay&req_reference_number=${orderId}&isQR`)
  })
}

const isCashOnDelivery = (orderData: OrderData) => {
  return (
    orderData.payment_type === PaymentTypes.CONTRA_ENTREGA ||
    orderData.payment_type?.includes(PaymentTypes.CONTRA_ENTREGA) ||
    orderData.payment_type === PaymentTypes.GIFT_CARD
  )
}

const handleCashOnDeliveryPayment = async (
  orderId: string,
  beforeRedirectCallback: () => Promise<void>,
): Promise<void> => {
  await beforeRedirectCallback()
  return navigate(`/payment/pending?toPay&req_reference_number=${orderId}`)
}

const handleNormalPayment = async (
  orderId: string,
  city: Market,
  sessionId: string,
  country: Country,
  sahcUrl: string,
  beforeRedirectCallback: () => Promise<void>,
): Promise<void> => {
  return redirectToPaymentGateway({
    countryCode: country,
    orderId,
    currentCity: city,
    sessionId,
    beforeRedirectCallback,
    sahcUrl,
  })
}

export {
  checkStockStatus,
  getPaymentTypeChile,
  orderSchema,
  loggedCheckoutSchema,
  updateAddresses,
  updateFavorites,
  validateOrder,
  processOrder,
  handleQrPayment,
  isCashOnDelivery,
  handleCashOnDeliveryPayment,
  handleNormalPayment,
}
