import React, { useEffect } from 'react'
import { useShoppingCart, useAuth, getStoredMarket } from '@ecommerce/shared'
import MyAddressesContainer from '../components/MyAccount/MyAddresses'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'
import { sendPageViewEvent } from '../utils/events'

const MyAddressesPage = ({ pageContext: { currentMarket, productCategories } }: PgPageProps) => {
  const storedMarket = getStoredMarket() ?? currentMarket
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Mis direcciones' })
  }, [])
  return <MyAddressesContainer currentMarket={storedMarket} categories={productCategories} />
}

export default withPageTransition(MyAddressesPage)
